:root {
  --brand-font: "Pacifico";
  --primary-color: var(--neonPink);
  --secondary-color: var(--neonBlue);
  --padding: 2rem;
}

#gallery ul li img, #books ul li img, #gallery ul li p {
  margin: 10px auto;
}

.card {
  background: #fff3;
  margin: 0;
}

a.button {
  color: var(--bg-color);
}

@keyframes spin {
  to {
    transform: rotate(45deg);
  }
}

h1 .hand {
  animation: .2s linear infinite alternate spin;
  display: inline-block;
}

header h1 b {
  font-family: Monoton;
}

@media screen and (width <= 600px) {
  .nav .button {
    text-align: center;
    width: 90%;
    margin: 4px 0;
  }
}

h2 {
  margin: -.6em 0 .4em;
}

.skills, .articles {
  padding: var(--padding);
  max-width: 600px;
  margin: 1em auto;
  font-size: 2rem;
}

h1, h2, h3, h4 {
  text-wrap: balance;
}
/*# sourceMappingURL=index.e3118f3f.css.map */
