:root{
  --brand-font: "Pacifico";
  --primary-color: var(--neonPink);
  --secondary-color: var(--neonBlue);
  --padding: 2rem;
}

#gallery ul li img,#books ul li img{
    margin: 10px auto;
  }

#gallery ul li p{
    margin: 10px auto;
  }

  .card{
    background: rgba(255,255,255,0.2);
    margin: 0;
  }

  a.button{
    color: var(--bg-color);
  }

@keyframes spin { 
    to { 
        transform: rotate(45deg);
    }
}

h1 .hand{
  animation: spin 0.2s linear alternate infinite;
  display: inline-block;
}

header h1 b{
  font-family: Monoton;
}

@media screen and (max-width: 600px) {
  .nav .button {
    width: 90%;
    margin: 4px 0;
    text-align: center;
  }
}

h2 {
  margin: -0.6em 0 0.4em;
}

.skills,.articles{
  max-width: 600px;
  margin: 1em auto;
  font-size: 2rem;
  padding: var(--padding);
}

h1,h2,h3,h4{
  text-wrap: balance;
}
